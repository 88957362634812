import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navToggle: false,
};

export const slices = createSlice({
  name: "navigationBar",
  initialState,
  reducers: {
    onClickToggle: (state, action) => {
      state.navToggle = action.payload;
    },
  },
});

export const { onClickToggle } = slices.actions;
export default slices.reducer;
