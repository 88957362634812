import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-simple-toasts";
import { postMethod } from "../API_SERVICES/ApiServices";
import logo from "../assets/logos/logo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./login.scss";
import Spinner from "react-bootstrap/Spinner";

function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  function sub(e) {
    e.preventDefault();
    let obj1 = { email: "", password: "" };
    obj1.email = document.getElementById("email").value;
    obj1.password = passwordInput;
    setLoading(true);
    postMethod("api/admin-authenticate", obj1)
      .then((res) => {
        console.log("RES", res?.data?.status);
        if (res?.data?.status === "success") {
          setLoading(false);
          console.log(res?.data?.results);
          localStorage.setItem("user", JSON.stringify(res?.data?.results));
          window.location.href = "/dashboard/enquiries";
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        setLoading(false);
      });
  }

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="container">
      <div className="login_wrapeer">
        <div className="loginform_container">
          <div
            className="form_img"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              style={{ maxWidth: "200px", textAlign: "center" }}
            ></img>
          </div>
          <Form onSubmit={sub} style={{ marginTop: "50px" }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                onFocus={(e) => {
                  if (e.target.value.length > 3) {
                    setError(null);
                  }
                }}
                required
                placeholder="User Name"
                id="email"
                style={{
                  background: " transparent",
                  borderRadius: "0",
                  fontSize: "0.9375rem",
                  height: "50px",
                  marginBottom: "20px",
                }}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <div className="input-group ">
                <input
                  id="password"
                  style={{
                    background: " transparent",
                    borderRadius: "0",
                    fontSize: "0.9375rem",
                    height: "50px",
                    marginBottom: "20px",
                  }}
                  onFocus={(e) => {
                    if (e.target.value.length > 3) {
                      setError(null);
                    }
                  }}
                  type={passwordType}
                  onChange={handlePasswordChange}
                  value={passwordInput}
                  name="password"
                  className="form-control rounded-0"
                  placeholder="Password"
                />
                <div className="input-group-btn">
                  <button
                    style={{
                      height: "50px",
                      background: "transparent",
                    }}
                    type="button"
                    className="form-control rounded-0"
                    onClick={togglePassword}
                  >
                    {passwordType === "password" ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
              </div>
              {error && <span className="text-danger">{error}</span>}
              <div>
                <a href="/forgot-password">Forgot Password</a>
              </div>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              style={{
                borderRadius: "0",
                width: "300px",
                height: "50px",
                backgroundColor: "#faa517",
                color: "white",
              }}
            >
              {loading ? "Please wait.." : "Submit"}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Login;
