import axios from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export const getDetailsOfBank = (code) => {
  let url = "https://ifsc.razorpay.com/" + code;
  return axios.get(url);
};

export const getData = async (URL) => {
  let url = API_URL + URL;

  console.log("url = API_URL + URL", url);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
    },
  });

  return response;
};

export const getMethod = (URL, signal) => {
  let url = API_URL + URL;

  return axios.get(url, {
    signal: signal,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const postMethod = (
  URL,
  Data,
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "POST,PUT,OPTIONS,GET",
  // }
) => {
  let url = API_URL + URL;

  return axios.post(url, Data);
};
export const updateMethod = (URL, Data) => {
  let url = API_URL + URL;

  return axios.put(url, Data);
};

export const fileupload = async (URL, Data) => {
  let url = API_URL + URL;
  const token = sessionStorage.getItem("token");
  const token_type = sessionStorage.getItem("token_type")
    ? sessionStorage.getItem("token_type")
    : "";

  const response = await axios.post(url, Data, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: token_type + " " + token,
    },
  });
  if (!response.ok) {
    if (response.status === 401) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token_type");

      window.location.href = "/user-pages/login";
    }
    return response;
  }
  return response;
};
