import React from "react";
import {
  MdMenu,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdSettings,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { onClickToggle } from "./NavbarSlice";

const Navbar = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const toggle = useSelector((state) => state?.navigationBarStore?.navToggle);
  const dispatch = useDispatch();
  const img_url = process.env.REACT_APP_IMAGE_URL;

  return (
    <div className="nav_header justify-content-between d-flex align-items-center ">
      <div style={{ width: "285px" }} className="bg-white border-2">
        <div
          style={{ height: "90px" }}
          className="d-flex justify-content-between align-items-center bg-white"
        >
          {!toggle && (
            <img
              className="p-2"
              width={180}
              alt="logo"
              src={require("../../assets/logos/logo.png")}
            />
          )}
          <MdMenu
            onClick={() => dispatch(onClickToggle(!toggle))}
            role="button"
            className={
              !toggle
                ? "w-70 text-primary fs-1 "
                : "toggle_button bg-secondary text-white"
            }
          />
          {toggle && (
            <img
              style={{ borderBottom: "2px solid #fc9200" }}
              className="p-3"
              height={90}
              width={250}
              alt="logo"
              src={require("../../assets/logos/logo.png")}
            />
          )}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <img
          className="rounded-5"
          alt="user"
          width={60}
          height={60}
          src={
            user?.profile_image !== null
              ? img_url + user?.profile_image
              : require("../../assets/logos/users.png")
          }
        />
        <div className="d-flex flex-column align-items-center justify-content-center p-5">
          <h5 className="text-white fw-bold">{user?.name}</h5>
          <h6 className="text-white">Admin</h6>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
