import React, { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";
import { useNavigate } from "react-router-dom";
import { postMethod, updateMethod } from "../../../API_SERVICES/ApiServices";
import FormComponent from "./FormComponent";
import { onNextPage, onPersonalDetails } from "./InvestRegisterSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

const PersonalInformation = ({ onNext }) => {
  const dispatch = useDispatch();
  const [succesMessage, setSuccessMessage] = useState(false);
  const [successPan, setSuccessPan] = useState(false);
  const [successAadhar, setSuccessAadhar] = useState(false);
  const [aadhaarCard, setAadhaarCard] = useState("");
  const MySwal = withReactContent(Swal);

  const [personalData, setPersonalData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  

  const investedDetails = useSelector((state) => state?.storedSates);
  const personalInformation = JSON.parse(sessionStorage.getItem("user"));
  console.log("personalInformation", personalInformation);
  useEffect(
    () => {
      console.log(
        personalData?.profile_image_file,
        "-----------------------sucess"
      );
      if (personalData?.profile_image_file != undefined) {
        setSuccessMessage(true);
      }
    },
    [personalData?.profile_image_file]
    // [personalData?.pan_card_file],
    // [personalData?.aadhar_card_file]
  );
  useEffect(() => {
    console.log(personalData?.pan_card_file, "-------------------pan success");
    if (personalData?.pan_card_file != undefined) {
      setSuccessPan(true);
    }
  }, [personalData?.pan_card_file]);
  useEffect(() => {
    console.log(
      personalData?.aadhar_card_file,
      "------------------aadhar success"
    );
    if (personalData?.aadhar_card_file != undefined) {
      setSuccessAadhar(true);
    }
  }, [personalData?.aadhar_card_file]);

  const [ccNumber, setCcNumber] = useState("");

  const formatAndSetCcNumber = (e) => {
    const inputVal = e.target.value.replace(/ /g, "");
    let inputNumbersOnly = inputVal.replace(/\D/g, "");

    if (inputNumbersOnly.length > 12) {
      inputNumbersOnly = inputNumbersOnly.substr(0, 12);
    }

    const splits = inputNumbersOnly.match(/.{1,4}/g);

    let spacedNumber = "";
    if (splits) {
      spacedNumber = splits.join(" ");
    }

    console.log("spacedNumber", spacedNumber);

    setCcNumber(spacedNumber);
    setPersonalData({
      ...personalData,
      aadhaar_card_number: spacedNumber,
    });
  };


  // const formattedDob = personalData?.dob ? moment(personalData.dob).format('DD/MM/YYYY') : '';


  const formDetails = [
    {
      id: 1,
      formLabel: "Name (as it appears on PAN Card)",
      type: "text",
      placeHolder: "Name",
      required: true,
      name: "name",
      isUpperCase: true,
      titleInfo: "Enter name as per PAN Card",
      error: errors.name,
      value: personalData?.name,
    },
    {
      id: 2,
      formLabel: "DOB (DD/MMM/YYYY)",
      type: "date",
      placeHolder: "DD-MMM-YYYY",
      required: true,
      name: "dob",
      titleInfo: "Enter Date of Birth",
      error: errors.dob,
      value: personalData?.dob,
    },
    {
      id: 3,
      formLabel: "Gender",
      type: "select",
      placeHolder: "Gender",
      required: true,
      name: "gender",
      option: [
        { id: "MALE", name: "Male" },
        { id: "FEMALE", name: "Female" },
        { id: "OTHERS", name: "Others" },
      ],
      titleInfo: "Select Gender",
      error: errors.gender,
    },
    {
      id: 4,
      formLabel: "Mobile Number",
      type: "number",
      placeHolder: "Mobile No.",
      required: true,
      name: "mobile_number",
      titleInfo: "Enter Mobile Number",
      error: errors.mobile_number,
      value: personalData?.mobile_number,
      maxLength: "10",
    },
    {
      id: 5,
      formLabel: "PAN Card Number",
      type: "text",
      placeHolder: "PAN Number",
      required: true,
      name: "pan_card_number",

      isUpperCase: true,
      titleInfo: "Enter PAN Card Number",
      error: errors.pan_card_number,
      value: personalData?.pan_card_number,
    },
    {
      id: 6,
      formLabel: "AADHAAR Number",
      type: "aadharCard",
      placeHolder: "AADHAAR Number",
      required: true,
      name: "aadhar_card_number",
      titleInfo: "Enter Adhaar Number",
      error: errors.aadhar_card_number,
      value: ccNumber,
      maxLength: "14",
    },
    {
      id: 7,
      formLabel: "Email",
      type: "email",
      placeHolder: "Email",
      required: true,
      name: "email",
      titleInfo: "Enter Email",
      error: errors.email,
      value: personalData?.email,
    },
    {
      id: 8,
      formLabel: "Upload Photo (Optional)",
      type: "file",
      placeHolder: "Upload Photo",
      required: false,
      name: "profile_image_file",
      error: errors.profile_image_file,

      info: succesMessage ? (
        <i style={{ color: "green" }}>
          <span className="mdi mdi-checkbox-marked-circle "></span>
          File uploaded sucessfully
        </i>
      ) : (
        <i>
          <span className="mdi mdi-information-outline text-warning"></span>
          Supported file type png, jpg of max size: 2mb
        </i>
      ),
    },
    {
      id: 9,
      formLabel: "Upload PAN Card ",
      type: "file",
      placeHolder: "Upload PAN",
      required: true,
      name: "pan_card_file",
      error: errors.pan_card_file,

      info: successPan ? (
        <i style={{ color: "green" }}>
          <span className="mdi mdi-checkbox-marked-circle "></span>
          File uploaded sucessfully
        </i>
      ) : (
        <i>
          <span className="mdi mdi-information-outline text-warning"></span>
          Supported file type png, jpg, pdf of max size: 2mb
        </i>
      ),
    },
    {
      id: 10,
      formLabel: "Upload Adhaar Card ",
      type: "file",
      placeHolder: "Upload Adhaar Card",
      required: true,
      name: "aadhar_card_file",
      error: errors.aadhar_card_file,

      info: successAadhar ? (
        <i style={{ color: "green" }}>
          <span className="mdi mdi-checkbox-marked-circle "></span>
          File uploaded sucessfully
        </i>
      ) : (
        <i>
          <span className="mdi mdi-information-outline text-warning"></span>
          Supported file type png, jpg, pdf of max size: 2mb
        </i>
      ),
    },
  ];

  const nextPage = () => {
    dispatch(onNextPage("yourAddress"));
  };

  const formRef = useRef([]);
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    if (personalInformation) {
      console.log("personalInformation", personalInformation?.id);
      console.log("pers", personalData);
      const formData = new FormData();
      formData.append("name", personalData?.name?.toUpperCase());
      formData.append("_method", "PUT");
      formData.append("is_draft", 1);

      formData.append("mobile_number", personalData?.mobile_number);
      formData.append("pan_card_file", personalData?.pan_card_file);
      formData.append(
        "pan_card_number",
        personalData?.pan_card_number?.toUpperCase()
      );
      formData.append("dob", personalData?.dob);
      formData.append("gender", personalData?.gender);
      formData.append(
        "aadhar_card_number",
        personalData?.aadhaar_card_number?.split(" ").join("")
      );
      formData.append("email", personalData?.email?.toUpperCase());
      formData.append(
        "profile_image_file",
        personalData?.profile_image_file
          ? personalData?.profile_image_file
          : null
      );
      formData.append("aadhar_card_file", personalData?.aadhar_card_file);
      formData.append("status", 1);
      formData.append("role_id", 3);
      formData.append("id", personalInformation?.id);

      console.log("ALert", errors);
      if (errors.pan_card_file && errors.aadhar_card_file) {
        console.log(errors.pan_card_file, "error");
      } else {
        postMethod("api/user/" + personalInformation?.id, formData)
          .then((res) => {
            console.log(res);
            if (res?.data?.status === true) {
              nextPage();
              onNext();
              dispatch(onPersonalDetails(res?.data?.data));
              sessionStorage.setItem("user", JSON.stringify(res?.data?.data));
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            setErrors({
              ...errors,
              pan_card_number: err?.response?.data?.errors?.pan_card_number,
              mobile_number: err?.response?.data?.errors?.mobile_number,
              aadhar_card_number:
                err?.response?.data?.errors?.aadhar_card_number,
              email: err?.response?.data?.errors?.email,
            });
            console.log(err?.response?.data?.errors);
            MySwal.fire({
              title: <strong>User Already Exist's</strong>,
              html: <i>Login with existing PAN Card number</i>,
              showConfirmButton: true,
              confirmButtonColor: "#fc9200",
              icon: "warning",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/";
              }
            });
          });
      }
    } else {
      const formData = new FormData();
      formData.append("name", personalData?.name?.toUpperCase());
      formData.append("mobile_number", personalData?.mobile_number);
      formData.append("pan_card_file", personalData?.pan_card_file);
      formData.append(
        "pan_card_number",
        personalData?.pan_card_number?.toUpperCase()
      );
      formData.append("dob", personalData?.dob);
      formData.append("gender", personalData?.gender);
      formData.append(
        "aadhar_card_number",
        personalData?.aadhaar_card_number?.split(" ").join("")
      );
      formData.append("email", personalData?.email?.toUpperCase());
      formData.append(
        "profile_image_file",
        personalData?.profile_image_file
          ? personalData?.profile_image_file
          : null
      );
      formData.append("aadhar_card_file", personalData?.aadhar_card_file);
      formData.append("status", 1);
      formData.append("role_id", 3);
      formData.append("is_draft", 1);

      const {
        aadhar_card_file,
        aadhar_card_number,
        email,
        mobile_number,
        name,
        pan_card_file,
        pan_card_number,
      } = errors;
      // console.log("ALert", errors);

      if (
        aadhar_card_file === null &&
        aadhar_card_number === null &&
        email === null &&
        mobile_number === null &&
        name === null &&
        pan_card_file === null &&
        pan_card_number === null
      ) {
        if (
          personalData.name !== "" &&
          personalData.pan_card_number !== undefined &&
          personalData.aadhar_card_number !== "" &&
          personalData.mobile_number !== ""
        ) {
          console.log("Errorrs", errors);

          setTimeout(() => {
            setLoading(true);
            postMethod("api/user", formData)
              .then((res) => {
                console.log(res);
                if (res?.data?.status === true) {
                  nextPage();
                  onNext();
                  dispatch(onPersonalDetails(res?.data?.data));
                  sessionStorage.setItem(
                    "user",
                    JSON.stringify(res?.data?.data)
                  );
                  setLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                setErrors({
                  ...errors,
                  pan_card_number: err?.response?.data?.errors?.pan_card_number,
                  mobile_number: err?.response?.data?.errors?.mobile_number,
                  aadhar_card_number:
                    err?.response?.data?.errors?.aadhar_card_number,
                });
                console.log(err?.response?.data?.errors);
                MySwal.fire({
                  title: <strong>User Already Exist's</strong>,
                  html: <i>Login with existing PAN Card number</i>,
                  showConfirmButton: true,
                  confirmButtonColor: "#fc9200",
                  icon: "warning",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.location.href = "/";
                  }
                });
              });
          }, 500);
        } else {
          if (personalData.name === "")
            return setErrors({ ...errors, name: "Enter Name as per PAN Card" });

          if (personalData.pan_card_number === undefined)
            return setErrors({
              ...errors,
              pan_card_number: "Enter PAN Number",
            });
          if (personalData.aadhar_card_number === "")
            return setErrors({
              ...errors,
              aadhar_card_number: "Enter Adhaar Number",
            });
          if (personalData.mobile_number === "")
            return setErrors({
              ...errors,
              mobile_number: "Enter Mobile Number",
            });
        }
      } else {
        setTimeout(() => {
          setLoading(true);
          postMethod("api/user", formData)
            .then((res) => {
              console.log(res);
              if (res?.data?.status === true) {
                nextPage();
                onNext();
                dispatch(onPersonalDetails(res?.data?.data));
                sessionStorage.setItem("user", JSON.stringify(res?.data?.data));
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);

              setErrors({
                ...errors,
                pan_card_number: err?.response?.data?.errors?.pan_card_number,
                email: err?.response?.data?.errors?.email,

                mobile_number: err?.response?.data?.errors?.mobile_number,
                aadhar_card_number:
                  err?.response?.data?.errors?.aadhar_card_number,
              });
              console.log(err?.response?.data?.errors);
            });
        }, 500);
      }
    }
  };

  useEffect(() => {
    console.log("aadharcard", aadhaarCard);
  }, [aadhaarCard]);

  const onHandleChange = (e) => {
    const { name, value, files } = e.target;

    // let formattedValue = value;

    // if (name === "dob") {
    //   formattedValue = moment(value, 'DD/MM/YYYY').toISOString(); // or any other format you need
    // }
    // setPersonalData({ ...personalData, [name]: name ==='dob'? moment(value).format(  'DD-MMM-YYYY'):value });
    setPersonalData({ ...personalData, [name]:value});
    // console.log(value, moment(value).format('DD-MMM-YYYY'),"mmm")
    // console.log(personalData?.dob,"sdfsd")

    if (name === "name") {
      if (value.length > 4) {
        setErrors({ ...errors, name: null });
        return true;
      }
    }

    if (name === "mobile_number") {
      if (value.length !== 10) {
        setErrors({ ...errors, mobile_number: "Invalid Mobile Number" });

        return false;
      } else {
        setErrors({ ...errors, mobile_number: null });

        return true;
      }
    }

    if (name === "email") {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (regex.test(value)) {
        console.log("regex.test(email)", regex.test(value));
        setErrors({ ...errors, email: null });

        return true;
      } else {
        setErrors({ ...errors, email: "Invalid Email" });

        return false;
      }
    }

    if (name === "aadhar_card_number") {
      let regex = /^\d{4}\d{4}\d{4}$/g;
      if (value.length === 12) {
        if (regex.test(value)) {
          console.log("regex.test(aadhar_card_number)", regex.test(value));
          setErrors({ ...errors, aadhar_card_number: null });

          setPersonalData({
            ...personalData,
            aadhaar_card_number: value,
          });

          return true;
        } else {
          setErrors({
            ...errors,
            aadhar_card_number: "Invalid Aadhaar Card Number",
          });

          return false;
        }
      } else {
        setErrors({
          ...errors,
          aadhar_card_number: "Invalid Aadhaar Card Number",
        });

        return false;
      }
    }

    if (name === "pan_card_number") {
      let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (value.length === 10) {
        if (regex.test(value)) {
          console.log("regex.test(pan_card_number)", regex.test(value));
          setErrors({ ...errors, pan_card_number: null });

          return true;
        } else {
          setErrors({ ...errors, pan_card_number: "Invalid PAN Card Number" });

          return false;
        }
      } else {
        setErrors({ ...errors, pan_card_number: "Invalid PAN Card Number" });

        return false;
      }
    }

    if (name === "profile_image_file") {
      if (files[0].size <= 2097152) {
        if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
          setPersonalData({ ...personalData, profile_image_file: files[0] });
          setErrors({ ...errors, profile_image_file: null });
        } else {
          setErrors({ ...errors, profile_image_file: "Unsupported File" });
          setPersonalData({ ...personalData, profile_image_file: null });
        }
      } else {
        setErrors({
          ...errors,
          profile_image_file: "File size should be less than 2MB",
        });

        setPersonalData({ ...personalData, profile_image_file: null });
      }
    }
    if (name === "aadhar_card_file") {
      if (files[0].size <= 2097152) {
        if (
          files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf"
        ) {
          setPersonalData({ ...personalData, aadhar_card_file: files[0] });
          setErrors({ ...errors, aadhar_card_file: null });
        } else {
          setErrors({ ...errors, aadhar_card_file: "Unsupported File" });
          setPersonalData({ ...personalData, aadhar_card_file: null });
        }
      } else {
        setErrors({
          ...errors,
          aadhar_card_file: "File size should be less than 2MB",
        });

        setPersonalData({ ...personalData, aadhar_card_file: null });
      }
    }
    if (name === "pan_card_file") {
      if (files[0].size <= 2097152) {
        if (
          files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf"
        ) {
          setErrors({ ...errors, pan_card_file: null });

          setPersonalData({ ...personalData, pan_card_file: files[0] });
        } else {
          setErrors({ ...errors, pan_card_file: "Unsupported File" });
          setPersonalData({ ...personalData, pan_card_file: null });
        }
      } else {
        setErrors({
          ...errors,
          pan_card_file: "File size should be less than 2MB",
        });

        setPersonalData({ ...personalData, pan_card_file: null });
      }
    }
  };

  return (
    <Fade>
      <Container>
        <form onSubmit={onSubmit}>
          <Row>
            {formDetails.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                <FormComponent
                  titleInfo={detail?.titleInfo}
                  key={index}
                  name={detail?.name}
                  info={detail?.info}
                  formRef={(el) => (formRef.current[index] = el)}
                  formLabel={detail?.formLabel}
                  type={detail?.type}
                  placeholder={detail?.placeHolder}
                  options={detail?.option}
                  required={detail?.required}
                  regex={detail?.regex}
                  isUpperCase={detail?.isUpperCase}
                  onHandleChange={onHandleChange}
                  validations={detail?.error}
                  value={detail?.value}
                  //defaultValue={detail?.value}
                  maxLength={detail?.maxLength}
                  aadharHandleChange={formatAndSetCcNumber}
                />
              </Col>
            ))}
          </Row>
          <div className="buttonGroup mb-3">
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="button"
              onClick={() => navigate("/")}
              variant="outline-warning rounded-0"
            >
              Close
            </Button>
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="submit"
              variant="warning rounded-0"
            >
              Next
              {loading ? (
                <Spinner
                  style={{
                    fontSize: "28px",
                    fontWeight: "100",
                    marginLeft: "5px",
                  }}
                  size="sm"
                  animation="border"
                  variant="light"
                />
              ) : (
                <span className="mdi mdi-chevron-right commonIcon"></span>
              )}
            </Button>
          </div>
        </form>
        <span className="">
          <small className="d-flex justify-content-lg-start align-items-center mb-3">
            <img
              width={20}
              alt="info"
              src={require("../../../assets/images/8.png")}
            />
            <span style={{ marginLeft: "5px" }}>Documents</span>
          </small>
        </span>
      </Container>
    </Fade>
  );
};

export default PersonalInformation;
