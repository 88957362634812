import { LinearProgress } from "@mui/material";

import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "../App.scss";
import InvestmentReports from "../components/Investments/Reports/InvestmentReports";
import AddInvestor from "../components/Investments/AddInvestor/AddInvestor";
import ExistingInvestment from "../components/Investments/ExistingInvestment/ExistingInvestment";

//loading components with suspense and lazy
//Dashboard Section
const Reports = lazy(() => import("../components/Dashboard/Reports/Reports"));
const Notifications = lazy(() =>
  import("../components/Dashboard/Notifications/Notifications")
);
const Enquiries = lazy(() =>
  import("../components/Dashboard/Notifications/Enquiries")
);
const AdminOnboarding = lazy(() =>
  import("../components/Dashboard/UserOnboarding/Onboarding")
);
const AdminUserList = lazy(() =>
  import("../components/Dashboard/UserOnboarding/UsersList")
);

//Projects
const AddProjects = lazy(() =>
  import("../components/Projects/AddProjects/AddProjects")
);
const ProjectsList = lazy(() =>
  import("../components/Projects/AddProjects/ProjectsList")
);
const CreateTranche = lazy(() =>
  import("../components/Projects/CreateTranche/CreateTranche")
);
const EditTranche = lazy(() =>
  import("../components/Projects/CreateTranche/CreateTranche")
);
const ViewTranche = lazy(() =>
  import("../components/Projects/CreateTranche/TrancheDetails")
);
const TrancheList = lazy(() =>
  import("../components/Projects/CreateTranche/TrancheList")
);

//Investments Sections
const InvestmentsList = lazy(() =>
  import("../components/Investments/Investments/Invsetments")
);
const Agents = lazy(() => import("../components/Investments/Agents/Agent"));
const Investors = lazy(() =>
  import("../components/Investments/Investors/Investor")
);
const InvestorDetails = lazy(() =>
  import("../components/Investments/Investors/InvestorDetails")
);
const InvestmentDetails = lazy(() =>
  import("../components/Investments/Investors/PayInvestor")
);
const Payments = lazy(() =>
  import("../components/Investments/Payments/Payments")
);
const PaymentDetails = lazy(() =>
  import("../components/Investments/Payments/PaymentDetails")
);
const UploadDocuments = lazy(() =>
  import("../components/Investments/UploadDocuments/UploadDocuments")
);

//Settings Section

const IntrestTds = lazy(() =>
  import("../components/Settings/Intrest&TDS/IntrestTds")
);
const Masters = lazy(() => import("../components/Settings/Masters/Masters"));
const MyProfile = lazy(() =>
  import("../components/Settings/MyProfile/MyProfile")
);
const Login = lazy(() => import("./Login"));
const PageNotFound = lazy(() => import("./NotFoundPage"));

const AppRouts = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("USER", user);

  return (
    <div className="App">
      <Suspense fallback={<LinearProgress color="warning" />}>
        <Routes>
          {/* DASHBOARD SECTION ROUTES */} 
          <Route
            exact
            path="/dashboard/reports"
            element={
              user !== null ? <Reports /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/settings/admin-onboarding"
            element={
              user !== null ? (
                <AdminOnboarding />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          
          <Route
            exact
            path="/settings/admin-users"
            element={
              user !== null ? (
                <AdminUserList />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/login"
            element={
              user !== null ? (
                <Navigate to="/dashboard/reports" replace />
              ) : (
                <Login />
              )
            }
          />
          <Route path="*" element={<PageNotFound />} />
          <Route
            exact
            path="/dashboard/notifications"
            element={
              user !== null ? (
                <Notifications />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/dashboard/enquiries"
            element={
              user !== null ? <Enquiries /> : <Navigate to="/login" replace />
            }
          />
          {/* DASHBOARD SECTION ROUTES */}
          {/* PROJECTS SECTION ROUTES */}
          <Route
            exact
            path="/projects/add-projects"
            element={
              user !== null ? <AddProjects /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/projects/edit-projects/:id"
            element={
              user !== null ? <AddProjects /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/projects/projects"
            element={
              user !== null ? (
                <ProjectsList />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/projects/create-tranche"
            element={
              user !== null ? (
                <CreateTranche />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/projects/tranche-details/:id"
            element={
              user !== null ? <ViewTranche /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/projects/tranches"
            element={
              user !== null ? <TrancheList /> : <Navigate to="/login" replace />
            }
          />

          {/* PROJECTS SECTION ROUTES */}
          {/* INVESTMENT SECTION ROUTES */}
          <Route
            exact
            path="/investment/investment-list"
            element={
              user !== null ? (
                <InvestmentsList />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/investment/investors"
            element={
              user !== null ? <Investors /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/investment/add-investor"
            element={
              user !== null ? <AddInvestor /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/investment/add-existing-investor"
            element={
              user !== null ? <ExistingInvestment /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/investment/investor-details/:id"
            element={
              user !== null ? (
                <InvestorDetails />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/investment/investment-details/:id"
            element={
              user !== null ? (
                <InvestmentDetails />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/investment/agents"
            element={
              user !== null ? <Agents /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/investment/upload-documents"
            element={
              user !== null ? (
                <UploadDocuments />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/investment/payments"
            element={
              user !== null ? <Payments /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/investment/pay-investor/:id"
            element={
              user !== null ? (
                <PaymentDetails />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            exact
            path="/investment/reports"
            element={
              user !== null ? (
                <InvestmentReports />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          {/* INVESTMENT SECTION ROUTES */}
          {/*SETTINGS SECTION ROUTES  */}
          <Route
            exact
            path="/settings/interest-tds"
            element={
              user !== null ? <IntrestTds /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/settings/masters"
            element={
              user !== null ? <Masters /> : <Navigate to="/login" replace />
            }
          />
          <Route
            exact
            path="/settings/my-profile"
            element={
              user !== null ? <MyProfile /> : <Navigate to="/login" replace />
            }
          />
          {/*SETTINGS SECTIONS ROUTES  */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default AppRouts;
