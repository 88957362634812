import React, { useState } from "react";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import AppRouts from "./pages/AppRouts";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ThemeProvider from "./theme";

function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log("USER", user);
  return user !== null ? (
    <ThemeProvider>
      <div>
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: "9999999",
            overflow: "hidden",
          }}
        >
          <Navbar />
        </div>
        <div className="d-flex ">
          <Sidebar />
          <div
            style={{
              width: "100%",
              maxHeight: "100%",
            }}
          >
            <AppRouts />
          </div>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  ) : window.location.pathname === "/forgot-password" ? (
    <ForgotPassword />
  ) : (
    <Login />
  );
}

export default App;
