import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Slide } from "react-reveal";
import {
  getMethod,
  postMethod,
  updateMethod,
} from "../../../API_SERVICES/ApiServices";
import FormComponent from "./FormComponent";
import { onAddressDetails, onNextPage } from "./InvestRegisterSlice";
const YourAddress = ({ onNext, onPrevious }) => {
  const [personalData, setPersonalData] = useState({
    door_no: null,
    street: null,
    country: null,
    state: null,
    district: null,
    city: null,
    pin_code: null,
    address_proof_type: null,
    upload_address_proof: null,

    cr_door_no: null,
    cr_street: null,
    cr_country: null,
    cr_state: null,
    cr_district: null,
    cr_city: null,
    cr_pin_code: null,
  });
  const [errors, setErrors] = useState({});
  const [countryDetails, setCountriesDetails] = useState([]);
  const [stateDetails, setStateDetails] = useState([]);
  const [cityDetails, setCityDetails] = useState([]);
  const [stateDDetails, setDStateDetails] = useState([]);
  const [cityDDetails, setDCityDetails] = useState([]);
  const [addressType, setAddressType] = useState([]);
  const [sameAddress, setSameAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [addressProof, setAddressProof] = useState(false);
  const investedDetails = useSelector(
    (state) => state?.storedSates?.investmentDetails
  );

  const activeKeyData = useSelector((state) => state?.storedSates?.activeTab);
  // const userDetails = useSelector(
  //   (state) => state?.storedSates?.personalDetails
  // );
  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const myAddress = JSON.parse(sessionStorage.getItem("address"));
  const myAddressId = myAddress?.id;
  console.log("activeKeyData", activeKeyData);
  console.log("myAddressId", myAddressId);

  useEffect(() => {
    const controller = new AbortController();

    getMethod("api/country", controller.signal).then((res) => {
      setCountriesDetails(res?.data?.data);

      console.log(res?.data?.data);
    });

    return () => {
      controller.abort();
    };
  }, []);
  useEffect(() => {
    console.log(
      personalData?.upload_address_proof,
      "---------------addressproof"
    );
    if (personalData?.upload_address_proof != undefined) {
      setAddressProof(true);
    }
  }, [personalData?.upload_address_proof]);
  // useEffect(() => {
  //   const controller = new AbortController();

  //   getMethod("api/state/" + personalData.country, controller.signal).then(
  //     (res) => {
  //       setStateDetails(res?.data?.data);
  //     }
  //   );

  //   return () => {
  //     controller.abort();
  //   };
  // }, [personalData?.country]);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   if (personalData?.state !== null) {
  //     getMethod("api/city/" + personalData.state, controller.signal).then(
  //       (res) => {
  //         setCityDetails(res?.data?.data);
  //       }
  //     );
  //   }

  //   return () => {
  //     controller.abort();
  //   };
  // }, [personalData?.state]);

  useEffect(() => {
    const controller = new AbortController();
    if (personalData?.pin_code !== null) {
      getMethod("api/address-document", controller.signal).then((res) => {
        let data = [];
        res?.data?.data?.forEach((ele) => {
          ele.name = ele.address_document_name;
          data.push(ele);
        });

        setAddressType(data);
      });
    }

    return () => {
      controller.abort();
    };
  }, [personalData?.pin_code]);

  const permanentAddress = [
    {
      id: 1,
      formLabel: "Door | Building | Apartment",
      type: "text",
      placeHolder: "Name",
      required: true,
      name: "door_no",
      value: personalData?.door_no,
    },
    {
      id: 2,
      formLabel: "Street | Area | Landmark",
      type: "text",
      placeHolder: "Street",
      required: true,
      name: "street",
      value: personalData?.street,
    },
    {
      id: 3,
      formLabel: "Country",
      type: "select",
      placeHolder: "county",
      required: true,
      name: "country",
      value: personalData?.country,
      option: countryDetails,
    },
    {
      id: 4,
      formLabel: "State",
      type: "select",
      placeHolder: "State ",
      required: true,
      name: "state",
      value: personalData?.state,
      option: stateDetails,
    },
    {
      id: 5,
      formLabel: "District",
      type: "text",
      placeHolder: "District",
      required: true,
      name: "district",
      value: personalData?.district,
    },
    {
      id: 6,
      formLabel: "City | Town | Village",
      type: "select",
      placeHolder: "Gender",
      required: true,
      name: "city",
      value: personalData?.city,
      option: cityDetails,
    },
    {
      id: 7,
      formLabel: "PIN Code",
      type: "text",
      placeHolder: "PIN Number",
      required: true,
      name: "pin_code",
      value: personalData?.pin_code,
    },
    {
      id: 8,
      formLabel: "Address Proof Type",
      type: "select",
      placeHolder: "Upload Photo",
      required: true,
      name: "address_proof_type",
      value: personalData?.address_proof_type,
      option: addressType,
    },
    {
      id: 9,
      formLabel: "Upload Address Proof",
      type: "file",
      placeHolder: "Upload Address",
      required: true,
      name: "upload_address_proof",
      value: personalData?.upload_address_proof,
      info: addressProof ? (
        <i style={{ color: "green" }}>
          <span className="mdi mdi-checkbox-marked-circle "></span>
          File uploaded sucessfully
        </i>
      ) : (
        <i>
          <span className="mdi mdi-information-outline text-warning"></span>
          Supported file type png, jpg, pdf of max size: 2mb
        </i>
      ),
    },
  ];
  const correspondenceAddress = [
    {
      id: 1,
      formLabel: "Door | Building | Apartment",
      type: "text",
      placeHolder: "Name",
      required: true,
      name: "cr_door_no",
      value: personalData?.cr_door_no,
    },
    {
      id: 2,
      formLabel: "Street | Area | Landmark",
      type: "text",
      placeHolder: "DD/MM/YYYY",
      required: true,
      name: "cr_street",
      value: personalData?.cr_street,
    },
    {
      id: 3,
      formLabel: "Country",
      type: "select",
      placeHolder: "county",
      required: true,
      name: "cr_country",
      value: personalData?.cr_country,
      option: countryDetails,
    },
    {
      id: 4,
      formLabel: "State",
      type: "select",
      placeHolder: "Mobile No.",
      required: true,
      name: "cr_state",
      value: personalData?.cr_state,
      option: sameAddress ? stateDetails : stateDDetails,
    },
    {
      id: 5,
      formLabel: "District",
      type: "text",
      placeHolder: "PAN Number",
      required: true,
      name: "cr_district",
      value: personalData?.cr_district,
    },
    {
      id: 6,
      formLabel: "City | Town | Village",
      type: "select",
      placeHolder: "Gender",
      required: true,
      name: "cr_city",
      value: personalData?.cr_city,
      option: sameAddress === true ? cityDetails : cityDDetails,
    },

    {
      id: 7,
      formLabel: "PIN Code",
      type: "text",
      placeHolder: "AAADHAAR Number",
      required: true,
      name: "cr_pin_code",
      value: personalData?.cr_pin_code,
    },
  ];

  const onHandleChange = (e) => {
    const controller = new AbortController();
    const { name, value, files } = e.target;
    console.log(name, value);

    if (name === "upload_address_proof") {
      setPersonalData({ ...personalData, upload_address_proof: files[0] });
    }

    setPersonalData({ ...personalData, [name]: value });
    setErrors({ ...errors, investment_period: null });
    if (name === "country") {
      getMethod("api/state/" + value, controller.signal).then((res) => {
        setStateDetails(res?.data?.data);
      });
    }
    if (name === "state") {
      getMethod("api/city/" + value, controller.signal).then((res) => {
        setCityDetails(res?.data?.data);
      });
    }
    if (name === "cr_country") {
      getMethod("api/state/" + value, controller.signal).then((res) => {
        setDStateDetails(res?.data?.data);
      });
    }
    if (name === "cr_state") {
      getMethod("api/city/" + value, controller.signal).then((res) => {
        setDCityDetails(res?.data?.data);
      });
    }
  };

  const nextPage = () => {
    dispatch(onNextPage("nomineeDetails"));
  };
  const previousPage = () => {
    dispatch(onNextPage("personalInformation"));
    onPrevious("personalInformation");
  };

  const formRef = useRef([]);

  const onSubmit = (e) => {
    e.preventDefault();

    if (myAddress) {
      console.log("myAddress", myAddress);
      let data = { ...personalData };
      data.address_proof_type = parseInt(personalData.address_proof_type);
      data.city = parseInt(personalData.city);
      data.state = parseInt(personalData.state);
      data.country = parseInt(personalData.country);
      data.cr_city = parseInt(personalData.cr_city);
      data.cr_state = parseInt(personalData.cr_state);
      data.cr_country = parseInt(personalData.cr_country);
      console.log("shhsdhdh", data);
      const formData = new FormData();
      formData.append("user_id", userDetails?.id);
      formData.append("address_document_id", data?.address_proof_type);
      formData.append("address_document", data?.upload_address_proof);
      formData.append("address", data?.door_no + " , " + data?.street);
      formData.append("country_id", data?.country);
      formData.append("state_id", data?.state);
      formData.append("district", data?.district);
      formData.append("city_id", data?.city);
      formData.append("postal_code", data?.pin_code);
      formData.append("is_correspondance_address", data?.address_proof_type);
      formData.append("address_1", data?.cr_door_no + " , " + data?.cr_street);
      formData.append("country_id_1", data?.cr_city);
      formData.append("state_id_1", data?.cr_state);
      formData.append("district_1", data?.cr_district);
      formData.append("city_id_1", data?.cr_city);
      formData.append("postal_code_1", data?.cr_pin_code);
      formData.append("status", 1);
      formData.append("_method", "PUT");
      console.log("gfdsfdd125512512", Object.fromEntries(formData));

      setTimeout(() => {
        setLoading(true);
        postMethod("api/user-address/" + myAddressId, formData)
          .then((res) => {
            console.log(res);
            if (res?.data?.status === true) {
              nextPage();
              onNext();
              setLoading(false);
              dispatch(onAddressDetails(res?.data?.data));
              sessionStorage.setItem(
                "address",
                JSON.stringify(res?.data?.data)
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            setErrors({
              ...errors,
              pan_card_number: err?.response?.data?.errors?.pan_card_number,
              mobile_number: err?.response?.data?.errors?.mobile_number,
              aadhaar_card_number:
                err?.response?.data?.errors?.aadhaar_card_number,
            });
            console.log(err?.response?.data?.errors);
          });
      }, 500);
    } else {
      let data = { ...personalData };
      data.address_proof_type = parseInt(personalData.address_proof_type);
      data.city = parseInt(personalData.city);
      data.state = parseInt(personalData.state);
      data.country = parseInt(personalData.country);
      data.cr_city = parseInt(personalData.cr_city);
      data.cr_state = parseInt(personalData.cr_state);
      data.cr_country = parseInt(personalData.cr_country);
      console.log("shhsdhdh", data);
      const formData = new FormData();
      formData.append("user_id", userDetails?.id);
      formData.append("address_document_id", data?.address_proof_type);
      formData.append("address_document_file", data?.upload_address_proof);
      formData.append("address", data?.door_no + " , " + data?.street);
      formData.append("country_id", data?.country);
      formData.append("state_id", data?.state);
      formData.append("district", data?.district);
      formData.append("city_id", data?.city);
      formData.append("postal_code", data?.pin_code);
      formData.append("is_correspondance_address", data?.address_proof_type);
      formData.append("address_1", data?.cr_door_no + " , " + data?.cr_street);
      formData.append("country_id_1", data?.cr_city);
      formData.append("state_id_1", data?.cr_state);
      formData.append("district_1", data?.cr_district);
      formData.append("city_id_1", data?.cr_city);
      formData.append("postal_code_1", data?.cr_pin_code);
      formData.append("status", 1);
      console.log("gfdsfdd125512512", Object.fromEntries(formData));

      setTimeout(() => {
        setLoading(true);
        postMethod("api/user-address", formData)
          .then((res) => {
            console.log(res);
            if (res?.data?.status === true) {
              nextPage();
              onNext();
              setLoading(false);
              dispatch(onAddressDetails(res?.data?.data));
              sessionStorage.setItem(
                "address",
                JSON.stringify(res?.data?.data)
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            setErrors({
              ...errors,
              pan_card_number: err?.response?.data?.errors?.pan_card_number,
              mobile_number: err?.response?.data?.errors?.mobile_number,
              aadhaar_card_number:
                err?.response?.data?.errors?.aadhaar_card_number,
            });
            console.log(err?.response?.data?.errors);
          });
      }, 500);
    }
  };

  return (
    <Fade>
      <Container>
        <h6 className="text-warning">Permanent Address</h6>
        <form onSubmit={onSubmit}>
          <Row>
            {permanentAddress.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                <FormComponent
                  titleInfo={detail?.titleInfo}
                  key={index}
                  name={detail?.name}
                  info={detail?.info}
                  formRef={(el) => (formRef.current[index] = el)}
                  formLabel={detail?.formLabel}
                  type={detail?.type}
                  placeholder={detail?.placeHolder}
                  options={detail?.option}
                  required={detail?.required}
                  regex={detail?.regex}
                  isUpperCase={detail?.isUpperCase}
                  onHandleChange={onHandleChange}
                  validations={detail?.error}
                  value={detail?.value}
                />
              </Col>
            ))}
          </Row>
          <h6 className="text-warning">Correspondence Address</h6>
          <small>
            <Form.Check
              className="mt-1 mb-3"
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked === true) {
                  setSameAddress(true);
                  setPersonalData({
                    ...personalData,
                    is_correspondance_address: 1,
                    cr_door_no: personalData.door_no,
                    cr_street: personalData.street,
                    cr_country: personalData.country,
                    cr_state: personalData.state,
                    cr_district: personalData.district,
                    cr_city: personalData.city,
                    cr_pin_code: personalData.pin_code,
                  });
                } else if (e.target.checked === false) {
                  setSameAddress(false);
                  setPersonalData({
                    ...personalData,
                    is_correspondance_address: 0,
                    cr_door_no: "",
                    cr_street: "",
                    cr_country: "",
                    cr_state: "",
                    cr_district: "",
                    cr_city: "",
                    cr_pin_code: "",
                  });
                }
              }}
              id="sel"
              name="is_sameAddress"
              label="Select, if correspondence address is same as permanent address"
            />
          </small>

          <Row>
            {correspondenceAddress.map((detail, index) => (
              <Col xs={12} sm={12} md={6} lg={4}>
                <FormComponent
                  titleInfo={detail?.titleInfo}
                  key={index}
                  name={detail?.name}
                  info={detail?.info}
                  formRef={(el) => (formRef.current[index] = el)}
                  formLabel={detail?.formLabel}
                  type={detail?.type}
                  placeholder={detail?.placeHolder}
                  options={detail?.option}
                  required={detail?.required}
                  regex={detail?.regex}
                  isUpperCase={detail?.isUpperCase}
                  onHandleChange={onHandleChange}
                  validations={detail?.error}
                  value={detail?.value}
                  selected={detail?.value}
                />
              </Col>
            ))}
          </Row>
          <div className="buttonGroup mb-3">
            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="button"
              onClick={previousPage}
              variant="outline-warning rounded-0"
            >
              <span className="mdi mdi-chevron-left commonIcon"></span>
              Previous
            </Button>

            <Button
              className="d-flex justify-content-center align-items-center commonBtn"
              type="submit"
              variant="warning rounded-0"

              // onClick={document.getElementById("topside").scroll(0, 0)}
            >
              Next
              {loading ? (
                <Spinner
                  style={{
                    fontSize: "28px",
                    fontWeight: "100",
                    marginLeft: "5px",
                  }}
                  size="sm"
                  animation="border"
                  variant="light"
                />
              ) : (
                <span className="mdi mdi-chevron-right commonIcon"></span>
              )}
            </Button>
          </div>
        </form>
      </Container>
    </Fade>
  );
};

export default YourAddress;
