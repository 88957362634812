import React, { useEffect, useState } from "react";
import {
  MdMenu,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdSettings,
} from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { SlLayers } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { onClickToggle } from "../Navbar/NavbarSlice";
import AssessmentIcon from "@mui/icons-material/Assessment";
const Sidebar = () => {
  const [toggleId, setToggleId] = useState();
  const [dashBoardToggle, setDashboardToggle] = useState(false);

  const dispatch = useDispatch();

  const toggle = useSelector((state) => state?.navigationBarStore?.navToggle);
  const location = useLocation();

  let activePath = location?.pathname;
  let openPath = location?.pathname?.split("/")[1];
  console.log("openPath", openPath);

  const paths = [
    {
      id: 1,
      name: "Dashboard",
      mainPath: "dashboard",
      icon: (
        <img
          width={30}
          alt="dashboard"
          src={require("../../assets/logos/dashboard.png")}
        />
      ),
      subPath: [
        {
          link: "/dashboard/enquiries",
          linkName: "Enquiries",
        },
        // {
        //   link: "/dashboard/notifications",
        //   linkName: "Notifications",
        // },
        // {
        //   link: "/dashboard/reports",
        //   linkName: "Reports",
        // },
      ],
    },
    {
      id: 2,
      name: "Projects",
      mainPath: "projects",
      icon: (
        <img
          width={30}
          alt="dashboard"
          src={require("../../assets/logos/projects.png")}
        />
      ),
      subPath: [
        {
          link: "/projects/projects",
          linkName: "Projects",
        },
        {
          link: "/projects/tranches",
          linkName: "Tranche",
        },
      ],
    },

    {
      id: 3,
      name: "Investments",
      mainPath: "investment",
      icon: (
        <img
          width={30}
          alt="dashboard"
          src={require("../../assets/logos/invest.png")}
        />
      ),
      subPath: [
        {
          link: "/investment/add-investor",
          linkName: "New Investment",
        },
        {
          link: "/investment/investment-list",
          linkName: "Investments",
        },
        {
          link: "/investment/investors",
          linkName: "Investors",
        },
        {
          link: "/investment/payments",
          linkName: "Payments",
        },

        {
          link: "/investment/agents",
          linkName: "Agents",
        },
      ],
    },
    {
      id: 4,
      name: "Settings",
      mainPath: "settings",
      icon: (
        <img
          width={30}
          alt="dashboard"
          src={require("../../assets/logos/settings.png")}
        />
      ),
      subPath: [
        {
          link: "/settings/admin-users",
          linkName: "Users Creation",
        },
        {
          link: "/settings/interest-tds",
          linkName: "Interest & TDS",
        },
        {
          link: "/settings/masters",
          linkName: "Master",
        },
        {
          link: "/settings/my-profile",
          linkName: "My Profile",
        },
      ],
    },
    {
      id: 5,
      name: "Reports",
      mainPath: "reports",
      icon: <AssessmentIcon />,
      subPath: [
        {
          link: "/investment/reports",
          linkName: "Investment Reports",
        },
      ],
    },
  ];

  const dashBoardClick = (pathId, option, index, presentPath) => {
    console.log(pathId, option, index);
    if (option === "x") {
        if (toggleId === pathId) {
            // If the clicked item is already open, close it
            setDashboardToggle(!dashBoardToggle);
        } else {
            // If a different item is clicked, open it and close others
            setToggleId(pathId);
            setDashboardToggle(true);
        }
        dispatch(onClickToggle(false));
    } else {
        // For other options, just set the state as needed
        setDashboardToggle(false);
    }
};


  // const dashBoardClick = (pathId, option, index, presentPath) => {
  //   console.log(pathId, option, index);
  //   setToggleId(pathId);
  //   if (option === "open") {
  //     setDashboardToggle(false);

  //   }
  //   if (option === "close") {
  //     setDashboardToggle(false);
  //   }
  //   if (option === "x") {
  //     setDashboardToggle(true);
  //     // setToggle(false);;
  //     dispatch(onClickToggle(false));
  //   } else {
  //     setDashboardToggle(false);
  //   }
  // };

  return (
    <div className="" style={{ position: "relative" }}>
      <div className={!toggle ? "sidebar_container" : "sidebar_container_sm"}>
        <div
          className={
            !toggle
              ? "d-flex flex-column justify-content-between "
              : "d-flex flex-column justify-content-between align-items-center "
          }
        >
          {paths?.map((path, index) => (
            <div key={index} className="mt-4">
              <h6
                onClick={() =>
                  dashBoardClick(path?.id, "x", index, path?.mainPath)
                }
                role="button"
                className={
                  !toggle
                    ? "bg-secondary text-white w-75 fw-bold p-2 d-flex justify-content-around align-items-center"
                    : "bg-primary  "
                }
              >
                {path?.icon}
                {!toggle && path?.name}
                {!toggle &&
                  (dashBoardToggle && path?.id === toggleId ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  ))}
              </h6>
              {!toggle &&
                dashBoardToggle &&
                path?.id === toggleId &&
                path?.subPath?.map((sub, index) => (
                  <h6 key={index} style={{ textIndent: "3.5rem" }}>
                    <Link
                      to={sub?.link}
                      role="button"
                      className={`text-white w-75 px-3 side_hover ${
                        activePath == sub?.link
                          ? "text-decoration-underline"
                          : "text-decoration-none"
                      } fw-bold`}
                    >
                      {sub?.linkName}
                    </Link>
                  </h6>
                ))}
            </div>
          ))}
          <div
            role="button"
            className="d-flex justify-content-center align-items-center mt-5"
          >
            <img
              width={30}
              alt="dashboard"
              src={require("../../assets/logos/logout.png")}
            />
            {!toggle && (
              <h5
                onClick={() => {
                  localStorage.clear();
                  sessionStorage.removeItem("investmentDetails");
                  sessionStorage.removeItem("bank");
                  sessionStorage.removeItem("nominee");
                  sessionStorage.removeItem("user");
                  sessionStorage.removeItem("tranche");
                  sessionStorage.removeItem("address");
                  window.location.href = "/login";
                }}
                className="text-white w-75 px-3 mt-auto"
              >
                Logout
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
