import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footerContainer">
      © 2023 by Mikro Grafeio Services Pvt. Ltd. All Rights Reserved.
    </div>
  );
};

export default Footer;
