import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-simple-toasts";
import { postMethod } from "../API_SERVICES/ApiServices";
import logo from "../assets/logos/logo.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import "./login.scss";
import Spinner from "react-bootstrap/Spinner";

function ForgotPassword() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  function sub(e) {
    e.preventDefault();
    let obj1 = { email: "", password: "" };
    obj1.email = document.getElementById("email").value;
    setLoading(true);
    postMethod("api/admin-authenticate", obj1)
      .then((res) => {
        console.log("RES", res?.data?.status);
        if (res?.data?.status === "success") {
          setLoading(false);
          console.log(res?.data?.results);
          localStorage.setItem("user", JSON.stringify(res?.data?.results));
          window.location.href = "/dashboard/enquiries";
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        setLoading(false);
      });
  }

  return (
    <div className="container">
      <div className="login_wrapeer">
        <div className="loginform_container">
          <div
            className="form_img"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="logo"
              src={logo}
              style={{ maxWidth: "200px", textAlign: "center" }}
            ></img>
          </div>
          <Form onSubmit={sub} style={{ marginTop: "50px" }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Enter Registered Email</Form.Label>
              <Form.Control
                type="email"
                onFocus={(e) => {
                  if (e.target.value.length > 3) {
                    setError(null);
                  }
                }}
                required
                placeholder="Email"
                id="email"
                style={{
                  background: " transparent",
                  borderRadius: "0",
                  fontSize: "0.9375rem",
                  height: "50px",
                  marginBottom: "20px",
                }}
              />
              {error && <span className="text-danger">{error}</span>}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              style={{
                borderRadius: "0",
                width: "300px",
                height: "50px",
                backgroundColor: "#faa517",
                color: "white",
              }}
            >
              {loading ? "Please wait.." : "Reset Password"}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
