import {
  Box,
  Button,
  Container,
  Grid,
  TableCell,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DateInputBRH } from "../../formIputs/DateInputBRH";
import { AutoCompleteInputBRH } from "../../formIputs/AutoCompleteInputBRH";
import MaterialReactTable from "material-react-table";
import { Link, useNavigate } from "react-router-dom";
import { MdRemoveRedEye } from "react-icons/md";
import moment from "moment";
import * as XLSX from "xlsx";
import { getMethod } from "../../../API_SERVICES/ApiServices";
import GetAppIcon from "@mui/icons-material/GetApp";

function InvestmentReports() {
  const [isHovered, setIsHovered] = useState(false);
  const [approvedInvestments, setApprovedInvestments] = useState([]);
  const [filteredInvestments, setfilteredInvestments] = useState([]);
  const [filters, setFilters] = useState({
    usersObj: null,
    trancheObj: null,
    startDate: null,
    endDate: null,
  });
  const [apiLoading, setApiLoading] = useState(false);

  const priceFormat = (str) => "₹" + parseInt(str).toLocaleString("en-IN");
  const TrancheData = approvedInvestments?.map((tranche) => {
    return tranche.tranche;
  });
  const trancheAmount = filteredInvestments?.map((tranche) => {
    return tranche.tranche.balance_tranche_amount;
  });
  const totalTranchAmount = trancheAmount.reduce(
    (prevamount, currentAmount, index) => prevamount + currentAmount,
    0
  );
  const UserData = approvedInvestments?.map((user) => {
    return user.user;
  });
  const uniqueTranchesSet = new Set();
  const uniqueTranchesArray = [];

  TrancheData.forEach((item) => {
    if (!uniqueTranchesSet.has(item.tranche_name)) {
      uniqueTranchesSet.add(item.tranche_name);
      uniqueTranchesArray.push(item);
    }
  });
  const uniqueUsersSet = new Set();
  const uniqueUsersArray = [];

  UserData.forEach((item) => {
    if (!uniqueUsersSet.has(item.name)) {
      uniqueUsersSet.add(item.name);
      uniqueUsersArray.push(item);
    }
  });

  useEffect(() => {
    setApiLoading(true);

    const controller = new AbortController();
    getMethod("api/user-investment", controller.signal).then((res) => {
      setApprovedInvestments(res?.data?.data?.filter((m) => m.status === 1));
      setfilteredInvestments(res?.data?.data?.filter((m) => m.status === 1));

      if (res?.data?.status === true) {
        setApiLoading(false);
      }
    });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const allFiltersNull = Object.values(filters).every(
      (filter) => filter === null
    );

    // If all filters are null, return the original array
    if (allFiltersNull) {
      setfilteredInvestments(approvedInvestments);
    } else {
      // Build an array of filter functions
      const filterFunctions = [
        (item) =>
          !filters.startDate ||
          !filters.endDate ||
          (new Date(item.created_at) >= filters.startDate &&
            new Date(item.created_at) <= filters.endDate),
        (item) => !filters.usersObj || item.user.name === filters.usersObj.name,
        (item) =>
          !filters.trancheObj ||
          item.tranche.tranche_name === filters.trancheObj.tranche_name,
        // ... add more filter functions for other conditions
      ];

      // Apply filters one by one
      const filteredArray = approvedInvestments.filter((item) =>
        filterFunctions.every((filterFn) => filterFn(item))
      );
      setfilteredInvestments(filteredArray);
    }
  }, [
    filters.trancheObj,
    filters.usersObj,
    filters.startDate,
    filters.endDate,
  ]);
  // Check if all filters are null

  const columns = useMemo(
    () => [
      {
        accessorKey: "0",
        header: "",
        enableSorting: false,
        size: 10,
        Cell: ({ row }) => (
          <Link
            to={`/investment/investment-details/${row?.original?.id}`} // state={{ investorDetails: row?.original }}
            className="link"
          >
            <MdRemoveRedEye className="fs-3 text-primary" />
          </Link>
        ),
      },
      {
        accessorKey: "start_date", //access nested data with dot notation
        header: "Investor Application Date",
        Cell: ({ cell }) => {
          return <span>{moment(cell.getValue()).format("DD-MMM-YYYY")}</span>;
        },
      },
      {
        accessorKey: "investment_code", //access nested data with dot notation
        header: "Investor Number",
        size: 200, //large column
      },
      {
        accessorKey: "user.name",
        header: "Investor Name",
      },
      {
        accessorKey: "start_date", //access nested data with dot notation
        header: "Investor Since",
        Cell: ({ cell }) => {
          return <span>{moment(cell.getValue()).format("DD-MMM-YYYY")}</span>;
        },
      },
      {
        accessorKey: "tranche.tranche_code", //normal accessorKey
        header: "Tranche Number",
        Cell: ({ cell }) => {
          return <span>{cell.getValue()}</span>;
        },
      },
      {
        accessorKey: "deposit_amount",
        header: "Investment Amount",
        Cell: ({ cell }) => {
          return <span>{priceFormat(cell.getValue())}</span>;
        },
      },
      {
        accessorKey: "roi",
        header: "Profit %",
        Cell: ({ cell }) => {
          return <span>{cell.getValue() + "%"}</span>;
        },
      },
      {
        accessorKey: "investment_period.investment_period",
        header: "Investment Period",
        Cell: ({ cell }) => {
          return <span>{cell.getValue() + " Months"}</span>;
        },
      },
      // {
      //   accessorKey: "interest_payment.interest_profit_payment",
      //   header: "Payment Frequency",
      //   Cell: ({ cell }) => {
      //     return <span>{cell.getValue()}</span>;
      //   },
      // },
      // {
      //   accessorKey: "interest_payment.interest_profit_payment",
      //   header: "Months Paid",
      //   Cell: ({ cell }) => {
      //     return <span>{cell.getValue()}</span>;
      //   },
      // },
      // {
      //   accessorKey: "interest_payment.interest_profit_payment",
      //   header: "Months Left",
      //   Cell: ({ cell }) => {
      //     return <span>{cell.getValue()}</span>;
      //   },
      // },
      // {
      //   accessorKey: "interest_payment.interest_profit_payment",
      //   header: "Total Profit Paid",
      //   Cell: ({ cell }) => {
      //     return <span>{cell.getValue()}</span>;
      //   },
      // },
      {
        accessorKey: "total_profit_amount",
        header: "Total profit amount",
        Cell: ({ cell }) => {
          return <span>{cell.getValue()}</span>;
        },
      },
    ],
    []
  );
  const investmentColumns = useMemo(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        // enableSorting: false,
        muiTableHeadCellProps: {
          align: "center",
          fontSize: "30px",
        },
        muiTableBodyCellProps: {
          align: "center",
          fontSize: "20px",
        },
        Cell: ({ cell }) => <span>{cell.getValue()}</span>,
      },
      {
        accessorKey: "amount", //access nested data with dot notation
        header: "amount",
        size: 150,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        Cell: ({ cell }) => {
          return <span>{cell.getValue()}</span>;
        },
      },
    ],
    []
  );

  const handleUserChange = (event, newValue) => {
    setFilters((prevDetails) => ({
      ...prevDetails,

      usersObj: newValue,
    }));
  };
  const handleTrancheChange = (event, newValue) => {
    setFilters((prevDetails) => ({
      ...prevDetails,

      trancheObj: newValue,
    }));
  };
  const modifiedArray = filteredInvestments?.map((item) => {
    return {
      "Investor Application Date": item.start_date,
      "Investor Number": item.investment_code,
      "Investor Name": item.user.name,
      "Investor Since": item.start_date,
      "Tranche Number": item.tranche.tranche_code,
      "Investment Amount": priceFormat(item.deposit_amount),
      "Profit %": item.roi + " %",
      "Investment Period": item.investment_period.investment_period + " Months",
      // "Payment Frequency": item.interest_payment.interest_profit_payment,
      // "Months Paid": item.interest_payment.interest_profit_payment,
      // "Months Left": item.interest_payment.interest_profit_payment,
      // "Total Profit Paid": item.interest_payment.interest_profit_payment,
      // "Last Payment Date": item.interest_payment.interest_profit_payment,
    };
  });
  const downloadasxl = () => {
    // Create a worksheet

    const workSheet = XLSX.utils.json_to_sheet(modifiedArray);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "Investment Report");
    //buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //binary
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //download
    XLSX.writeFile(workBook, `InvestmentReports.xlsx`);
  };

  return apiLoading ? (
    <>
      <Container className="text-center">
        <div className="loader"></div>
      </Container>
    </>
  ) : (
    <>
      <h1 className="mt-3 mx-5">Investment Report</h1>
      <Container>
        <Box sx={{ marginTop: "16px", padding: "16px " }}>
          <h1 style={{ marginTop: "10px", fontSize: "20px", fontWeight: 600 }}>
            Select Date Range
          </h1>
          <Grid
            container
            alignItems="center"
            className="d-flex flex-row align-items-center"
            rowSpacing={2.5}
            columnSpacing={{ xs: 2, sm: 2, md: 2 }}
          >
            <Grid item sm={12} md={3}>
              <DateInputBRH
                label="Start date"
                name="StartDate"
                format="dd-MM-yyyy"
                value={filters.startDate}
                handleChange={(value) =>
                  setFilters((prev) => ({ ...prev, startDate: value }))
                }
              />
            </Grid>
            {/* endDate */}
            <Grid item sm={12} md={3}>
              <DateInputBRH
                label="End Date"
                name="EndDate"
                format="dd-MM-yyyy"
                value={filters.endDate}
                handleChange={(value) =>
                  setFilters((prev) => ({ ...prev, endDate: value }))
                }
                minDate={filters.startDate}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              {" "}
              <Button
                onClick={() => {
                  setFilters((prev) => ({
                    ...prev,
                    startDate: null,
                    endDate: null,
                  }));
                }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          <h1 style={{ marginTop: "20px", fontSize: "20px", fontWeight: 600 }}>
            Filters
          </h1>
          <Grid
            container
            alignItems="center"
            rowSpacing={2.5}
            columnSpacing={{ xs: 2, sm: 2, md: 2 }}
          >
            <Grid item sm={12} md={3}>
              <AutoCompleteInputBRH
                label="Tranche"
                name="tranche_name"
                options={uniqueTranchesArray}
                value={filters.trancheObj}
                handleChange={handleTrancheChange}
              />
            </Grid>
            {/* endDate */}
            <Grid item sm={12} md={3}>
              <AutoCompleteInputBRH
                label="Users"
                name="name"
                options={uniqueUsersArray}
                value={filters.usersObj}
                handleChange={handleUserChange}
              />
            </Grid>
          </Grid>
          <Grid className="d-flex flex-column align-items-end">
            <Button
              onClick={downloadasxl}
              variant="contained"
              sx={{
                backgroundColor: "white",

                color: isHovered ? "#ffffff" : "#faa619",
                cursor: "pointer",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              endIcon={<GetAppIcon />}
            >
              Export
            </Button>
            <MaterialReactTable
              columns={columns}
              data={filteredInvestments}
              muiTableHeadCellProps={{
                //no useTheme hook needed, just use the `sx` prop with the theme callback
                sx: (theme) => ({
                  color: "#fff",
                  backgroundColor: theme.palette.text.primary,
                  textAlign: "center",
                }),
              }}
              renderDetailPanel={({ row }) => {
                const data = row.original.new_investment_detail.filter(
                  (inv) => new Date(inv.date) <= new Date()
                );
                return (
                  <MaterialReactTable
                    columns={investmentColumns}
                    data={data}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    // enablePagination={false}
                    enableSorting={false}
                    // enableBottomToolbar={false}
                    enableTopToolbar={false}
                    muiTableBodyRowProps={{ hover: false }}
                    muiTablePaperProps={{
                      sx: {
                        width: "80%",
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        fontSize: "30px",
                      },
                    }}
                    muiTableBodyCellProps={{
                      sx: {
                        fontSize: "20px",
                      },
                    }}
                  />
                );
              }}
              muiTablePaginationProps={{
                showFirstButton: false,
                showLastButton: false,
              }}
              // enableGlobalFilter={true}
              // positionGlobalFilter="left"
              // muiSearchTextFieldProps={{
              //   placeholder: `Search `,
              //   sx: { minWidth: "300px" },
              //   variant: "outlined",
              // }}
              enableDensityToggle={false}
              initialState={{ density: "compact", showColumnFilters: false }}
              enableHiding={false}
              enableColumnFilters={true}
              muiTableHeadCellFilterTextFieldProps={{
                sx: {
                  width: "100%",
                  backgroundColor: "#fff",
                },
                variant: "outlined",
              }}
              enableColumnActions={false}
              enableStickyHeader
              muiTableContainerProps={{ sx: { maxHeight: "350px" } }}
              defaultColumn={{
                maxSize: 400,
                minSize: 80,
                size: 150, //default size is usually 180
              }}
              enableColumnResizing
              muiTableProps={{
                sx: {},
              }}
              muiTablePaperProps={{
                sx: {
                  elevation: 2,
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  marginTop: "20px",

                  width: "70vw",
                },
              }}
              columnResizeMode="onChange"
            />
          </Grid>
          <h1
            style={{
              fontSize: "25px",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: 600,
            }}
          >
            Total Tranche Amount:-{" "}
            <span
              style={{ fontSize: "25px", marginTop: "20px", fontWeight: 500 }}
            >
              {totalTranchAmount}
            </span>
          </h1>
        </Box>
      </Container>
    </>
  );
}

export default InvestmentReports;
